var arr = [
  {
    title: 'الرئيسية',
    route: '/',
    icon: 'HomeIcon',
  },
  {
    title: 'سجل العمليات',
    route: 'orders',
    icon: 'ListIcon',
  },
  {
    title: 'الحسابات',
    route: 'schools',
    icon: 'UsersIcon',
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive',
    icon: 'ArchiveIcon',
  },
  {
    title: 'اكواد الخصم',
    route: 'offers',
    icon: 'DollarSignIcon',
  },
  {
    title: 'ارسال للمنتهيين',
    route: 'send',
    icon: 'SendIcon',
  },
  {
    title: 'الحسابات',
    route: 'stats',
    icon: 'DollarSignIcon',
  }
]

export default arr
